@import '../../../../scss/theme-bootstrap';

.product-foundation-vto {
  &__undertone-background {
    position: absolute;
    background-color: $color-black;
    width: 100%;
    height: 100%;
    z-index: 101;
    opacity: 0.8;
  }
  &__undertone {
    @include swap_direction(margin, 10px 15px 15px);
    position: fixed;
    width: 92%;
    padding: 10px;
    z-index: 999;
    top: 8%;
    background-color: $color-white;
  }
  &__undertone-close {
    width: 50px;
    height: 50px;
    cursor: pointer;
    position: absolute;
    #{$rdirection}: 10px;
    top: 33px;
    z-index: 9999999;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url('https://www.bobbibrowncosmetics.ca/media/export/cms/foundation_virtual_try_on/overlay-close.png');
  }
  &__undertone-title {
    @include swap_direction(padding, 30px 0 0 15px);
    font-family: $bb-sans;
    font-size: 36px;
    letter-spacing: -1.9px;
    line-height: 36px;
  }
  &__undertone-header {
    @include swap_direction(padding, 25px 0 0 15px);
    font-family: $body-font-bold;
    font-size: 16px;
    font-weight: bold;
    line-height: 30px;
  }
  &__undertone-copy {
    @include swap_direction(padding, 10px 10px 0 15px);
    font-size: 15px;
    font-family: $bbtext-regular;
    letter-spacing: 0.5px;
    width: 90%;
  }
  &__undertone-importance-copy {
    @include swap_direction(padding, 10px 0 30px 15px);
    font-size: 15px;
    font-family: $bbtext-regular;
    letter-spacing: 0.5px;
    width: 90%;
  }
}
